<template>
	<span>
		<vue-html2pdf
			:show-layout="false"
			:float-layout="true"
			:enable-download="true"
			:preview-modal="false"
			:paginate-elements-by-height="1350"
			:filename="fileName"
			pdf-format="a4"
			ref="html2Pdf"
		>
			<section slot="pdf-content">
				<div
					style="margin: 50px; font-family: 'Avenir', Helvetica, Arial, sans-serif;"
				>
					<div :style="styles.pageTitle">{{pageTitle}}</div>
					<div v-for="(item, i) in value" :key="i">
						<div :style="styles[item.element]"  v-html="item.content">>
						</div>
					</div>
				</div>
			</section>
		</vue-html2pdf>
		<v-btn v-if="icon" icon @click="go" outlined>
			<v-icon v-if="icon">mdi-download</v-icon>
		</v-btn>
		<v-list-item v-else @click="go">
			<v-list-item-title>
				{{ $t("buttons.download") }}
			</v-list-item-title>
		</v-list-item>
	</span>
</template>

<style lang="less"></style>

<script type="text/javascript">
import VueHtml2pdf from "vue-html2pdf";
export default {
	name: "Pdf",
	props: {
		value: { type: Array },
		icon: { type: Boolean },
		fileName: { type: String, default: "dma" },
		outlined: { type: Boolean },
	},
	data: () => {
		return {
			styles: {
				pageTitle: {
					"line-height": "22px",
					"font-weight": "bold",
					"margin-bottom": "28px",
					"font-size": "22px"
				},
				title: {
					"line-height": "18px",
					"font-weight": "bold",
					"margin-bottom": "22px",
					"font-size": "22px"
				},
				question: {
					"line-height": "16px",
					"font-weight": "bold",
					"margin-bottom": "4px",
					"margin-top": "15px",
					"font-size": "16px"
				},
				answer: {
					"line-height": "13px",
					"font-weight": "normal",
					"margin-bottom": "4px",
					"font-size": "16px"
				},
			}
		}
	},
	computed: {
		pageTitle(){
			return this.$store.getters["config/assessmentTitle"];
		}
	},
	components: {
		VueHtml2pdf,
	},
	methods: {
		go() {
			this.$refs.html2Pdf.generatePdf();
		},
	},
};
</script>
